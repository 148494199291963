import React, { useEffect, useState } from "react";
import Header from "../components/BasicComponents/Header";
import banner from "../../../images/u-a-e-banner.jpg";
import SearchLocation from "../components/BasicComponents/searchLocation";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import stickyLogo from "../../../images/ponties.png";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
interface Location {
  geometry?: {
    location?: {
      lat: () => number;
      lng: () => number;
    };
  };
}
const ZOHO_CLIENT_ID = "1000.I49V0UGTNXH78JGRJ4OFR8XMEMAO9M";
const ZOHO_CLIENT_SECRET = "4c2cee46c26cd4a7126f88ddb73cc8b6dfdc3de7fb";
const REDIRECT_URI = "https://www.pontis.ae";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [allCompanies, setAllCompanies] = useState<any>([]);
  const [allCities, setAllCities] = useState<any>([]);
  const [buildingType, setBuildingType] = useState<any>([]);
  const [propertyLabel, setPropertyLabel] = useState<any>([]);

  const [categoryId, setCategoryId] = useState<string>("");
  console.log(buildingType, "categoryId");
  const [propertyType, setPropertyType] = useState<any>([]);
  const [type, setType] = useState<string>("");
  console.log(type, "type");
  const [selectedlocation, setSelectedLocation] = useState<any>();
  const [lat, setLat] = useState<any>();
  const [lng, setLng] = useState<any>();
  console.log(selectedlocation?.geometry?.location?.lat, "location");

  const handleCategoryChange = (value: string) => {
    setCategoryId(value);
  };

  const handleTypeChange = (value: string) => {
    setType(value);
  };

  const [allProperties, setAllProperties] = useState<any>([]);

  var settings = {
    dots: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    overflow: 'hidden',
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  var bannerSlider = {
    dots: false,
    speed: 1000,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    adaptiveHeight: true,
    fade: true,
    cssEase: "linear",
  };

  var companyList = {
    dots: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const getAllCompany = async () => {
    try {
      const response = await axios.get(`${API_URL}/company/get/all/`);
      if (response.status === 200) {
        setAllCompanies(response.data);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  const getAllProperties = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/properties/advance/filter/?dist=1000&property_label=9c9cd2a0-ef4f-4151-b7ab-17496bd3d6c0`
      );
      if ((response.status = 200)) {
        setAllProperties(response.data);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  const getAllCities = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/state/image/`);
      if ((response.status = 200)) {
        setAllCities(response.data);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  const getPropertyLabel = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/property/label/`);
      if ((response.status = 200)) {
        const responseData = response?.data;
        setPropertyLabel(responseData);
      } else {
      }
    } catch (err) {}
  };

  const getBuildType = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/building/types/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setBuildingType(responseData);
      } else {
        console.error("Request failed with status:", response.status);
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  };
  const getPropertyType = async (id) => {
    if (id !== null) {
      try {
        const response = await axios.get(
          `${API_URL}/properties/property/types/?building_type=${id}`
        );
        if (response.status === 200) {
          const responseData = response.data;
          setPropertyType(responseData);
        } else {
          toast.error(" Please Contact Administrator");
        }
      } catch (err) {
        toast.error(" Please Contact Administrator");
      }
    }
  };

  useEffect(() => {
    getAllCompany();
    getAllProperties();
    getAllCities();
    getBuildType();
    getPropertyLabel();
  }, []);
  useEffect(() => {
    if (selectedlocation) {
      setLat(selectedlocation.geometry.location.lat());
      setLng(selectedlocation.geometry.location.lng());
    }
  }, []);
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    // if (!location) {
    //   toast.error("Location is required!");
    //   return;
    // }

    const searchData = {
      lat,
      lng,
      categoryId,
      type,
      searchText,
    };
    navigate("/properties", { state: searchData });
  };

  const commericalFunction = async (id) => {
    try {
      const allcommerical = await getCommericalProperties(id); // Wait for the properties to be fetched

      navigate("/properties", {
        state: { allcommerical, building_type: buildingType[0]?.id },
      });
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };
  const residentialFunction = async (id) => {
    try {
      const allcommerical = await getCommericalProperties(id); // Wait for the properties to be fetched

      navigate("/properties", {
        state: { allcommerical, building_type: buildingType[1]?.id },
      });
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };
  const offPlanFunction = async () => {
    try {
      navigate("/properties", { state: { is_property: true } });
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };
  const getCommericalProperties = async (id) => {
    try {
      let apiUrl = `${API_URL}/properties/advance/filter/?building_type=${id}`;
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        const responseData = response.data;
        // setAllProperties(responseData);
        return responseData;
      } else {
        // Handle unexpected status codes, if needed
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching properties:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };
  const propertyState = (cityName) => {
    navigate("/properties", { state: { city: cityName } });
  };

  // useEffect(() => {
  //   setShowModal(true);
  // }, []);

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleFormSubmit = async () => {
    const { name, email, phone, description } = formData;

    if (!name || !email) {
      toast.error("Name and Email are required.");
      return;
    }

    try {
      // Create a form data object
      const zohoFormData = new FormData();
      zohoFormData.append("name", name);
      zohoFormData.append("email", email);
      zohoFormData.append("mobile", phone);
      zohoFormData.append("msg", description);

      // Send form data to Zoho CRM with axios
      const response = await axios.post(
        `${API_URL}/account/contact/create/`,
        zohoFormData
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("We Will Contact You Soon");

        setFormData({
          name: "",
          email: "",
          phone: "",
          description: "",
        });
      } else {
        toast.error("Failed to submit form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  return (
    <main id="landing-main-page">
      <div className="th-hero-wrapper hero-3 no-mean" id="hero-3">
        <Slider {...bannerSlider} className="commercial-set ">
          <img className="hero-video" src="../../assets/img/top-banner-2.jpg" />
          <img className="hero-video" src="../../assets/img/u-a-e-banner.jpg" />
          <img
            className="hero-video"
            src="../../assets/img/skyline-wallpaper.jpg"
          />
          <img
            className="hero-video"
            src="../../assets/img/aerial-view-city-by-water.jpg"
          />
        </Slider>
        <div className="container">
          <div
            className="row gy-5 justify-content-center"
            style={{ marginTop: "150px" }}
          >
            <div className="col-12">
              <div className="hero-style3 text-center">
                <div className="btn-wrap justify-content-center">
                  <a
                    style={{ cursor: "pointer" }}
                    // onClick={() => residentialFunction(buildingType[1]?.id)}
                    className="th-btn btn_class"
                    href="/residential-properties"
                  >
                    Residential
                  </a>

                  <a
                    className="th-btn btn_class"
                    style={{ cursor: "pointer" }}
                    // onClick={() => commericalFunction(buildingType[0]?.id)}
                    href="/commercial-properties"
                  >
                    Commercial
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    // onClick={() => offPlanFunction()}
                    className="th-btn btn_class"
                    href="/off-plan-properties"
                  >
                    Off Plan
                  </a>
                </div>
                <h1 className="hero-title text-white fs-18 no-mean">
                  Experience The Harmonious
                  <br /> Blend Of Luxury
                </h1>
                <form className="property-search-form" onSubmit={handleSearch}>
                  <label>Property Search</label>
                  <div className="form-group">
                    <i className="far fa-search"></i>

                    <SearchLocation onLocationSelect={setSelectedLocation} />
                  </div>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      handleCategoryChange(e.target.value);
                      getPropertyType(e.target.value);
                    }}
                  >
                    <option value="">Category</option>
                    {buildingType?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>

                  <select
                    className="form-select"
                    onChange={(e) => handleTypeChange(e.target.value)}
                  >
                    <option value="">Type</option>
                    {propertyType?.map((item) => (
                      <option key={item.id} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                  <div className="form-group">
                    <div
                      className=""
                      style={{
                        borderLeft: "1px solid #1C2D37",
                        height: "18px",
                        display: "inline",
                      }}
                    >
                      <input
                        id="le_lu_ri"
                        type="text"
                        style={{ height: "18px !important" }}
                        data-kt-user-table-filter="search"
                        onChange={(e) => setSearchText(e?.target?.value)}
                        placeholder="Enter Keyword..."
                      />
                    </div>
                  </div>
                  <button className="th-btn" type="submit">
                    <i className="far fa-search"></i> Search
                  </button>
                </form>
                <div className="scroll-down">
                  <a href="#property-sec" className="hero-scroll-wrap"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ******************mobile HEADER********** */}
      <div className="d-lg-none">
        <div
          className="breadcumb-wrapper"
          style={{ backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')" }}
        >
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-xl-9">
                <div className="breadcumb-content">
                  <h1 className="breadcumb-title">
                    {" "}
                    Experience The Harmonious Blend Of Luxury
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-2">
          <div className="hero-style3 text-center">
            <form className="property-search-form" onSubmit={handleSearch}>
              <label>Property Search</label>
              <div className="form-group">
                <i className="far fa-search"></i>

                <SearchLocation onLocationSelect={setSelectedLocation} />
              </div>
              <select
                className="form-select"
                onChange={(e) => {
                  handleCategoryChange(e.target.value);
                  getPropertyType(e.target.value);
                }}
              >
                <option value="">Category</option>
                {buildingType?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>

              <select
                className="form-select"
                onChange={(e) => handleTypeChange(e.target.value)}
              >
                <option value="">Type</option>
                {propertyType?.map((item) => (
                  <option key={item.id} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
              <button className="th-btn" type="submit">
                <i className="far fa-search"></i> Search
              </button>
            </form>
            <div className="scroll-down">
              <a href="#property-sec" className="hero-scroll-wrap"></a>
            </div>
          </div>
        </div>
      </div>
      {/* ******************all cities********** */}

      <section className="space overflow-hidden" id="property-sec">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xxl-12 col-lg-12 ">
              <div className="title-area">
                <span className="sub-title">Popular Areas in UAE</span>
              </div>
            </div>
          </div>
          <div className=" tab-content property-tab-content position-relative">
            <div className="container row">
              {allCities?.map((item) => (
                <div
                  key={item?.id}
                  className="col-lg-3 col-md-6 col-sm-12"
                  style={{ padding: "10px", cursor: "pointer" }}
                  onClick={() => propertyState(item?.state)}
                >
                  <div
                    className="city-thumb d-flex"
                    style={{ borderRadius: "5px", border: "1px solid #f2f2f2" }}
                  >
                    <div className=" img-shine">
                      <img
                        src={item?.image}
                        alt="img"
                        // style={{
                        //   width: "100px",
                        //   height: "100px",
                        //   borderTopLeftRadius: "5px",
                        //   borderBottomLeftRadius: "5px",
                        // }}
                      />
                    </div>
                    <div className="media-left" style={{ padding: "10px" }}>
                      <h2 className="city-title">
                        <a>{item?.state}</a>
                      </h2>

                      {/* <p style={{ margin: "0px" }}>Properties For Sale </p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/**************************  featured project ******************************/}

      <section className="space overflow-hidden" id="property-section">
        <div className="container">
          <div className="row justify-content-between align-items-right">
            <div className="col-xxl-5 col-lg-8">
              <div className="title-area">
                <span className="sub-title">Featured Listings</span>

                {/* <p className="text-theme">Quis nulla blandit vulputate morbi adipiscing sem vestibulum. Nulla turpis integer dui sed posuere sem. Id molestie mi arcu gravida lorem potenti.</p> */}
              </div>
            </div>
          </div>
          <div className="tab-content property-tab-content position-relative">
            <div className="row">
              <Slider {...settings} className="commercial-set">
                {allProperties?.map((item) => (
                  <div
                    key={item?.id}
                    className="col-lg-4 col-md-6 col-sm-12 padding-15"
                  >
                    <div
                      style={{
                        border: "1px solid #d3d3d3",
                        borderRadius: "10px",
                        padding: "15px",
                        position: "relative",
                      }}
                    >
                      <div
                        className="property-custom-card"
                        style={{ position: "relative" }}
                      >
                        {/* Image Container */}
                        <div
                          className="property-card-thumb img-shine"
                          style={{ position: "relative" }}
                        >
                          {item?.is_property ? (
                            // Badge
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                backgroundColor: "#FD5900",
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontWeight: "bold",
                                fontSize: "12px",
                                zIndex: 2,
                              }}
                            >
                              OFF PLAN
                            </div>
                          ) : null}
                          {/* Featured Image */}
                          <img
                            src={item?.upload_featured_image}
                            alt="img"
                            style={{ width: "100%", borderRadius: "10px" }}
                          />
                        </div>

                        {/* Company Logo */}
                        <div className="feature-img-wrapper">
                          <img
                            className="feature-img"
                            src={item?.company_img}
                            alt="Image"
                          />
                        </div>

                        {/* Property Info */}
                        <div className="property-info">
                          <div className="text-center">
                            <div style={{height:"110px"}}>
                            <h4 className="property-title text-dark">
                              <a href={`/property/${item?.slug}`}>
                                {item?.property_name}
                              </a>
                            </h4>
                            <p className="property-address">
                              {item?.city}
                              {", "}
                              {item?.state}
                              {", "}
                              {item?.country}
                            </p>
                            </div>
                            <a
                              href={`/property/${item?.slug}`}
                              className="th-btn style-blue2"
                              
                            >
                              Register Your Interest
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* *************************company data*********************************** */}

      <div className="client-area-1 bg-white space">
        <div className="container">
          <div className="row justify-content-between align-items-right">
            <div className="col-xxl-5 col-lg-8">
              <div className="title-area">
                <span className="sub-title">Featured Developers</span>

                {/* <p className="text-theme">Quis nulla blandit vulputate morbi adipiscing sem vestibulum. Nulla turpis integer dui sed posuere sem. Id molestie mi arcu gravida lorem potenti.</p> */}
              </div>
            </div>
            <div className="col-auto">
              <div className="sec-btn">
                <a href="/company" className="th-btn">
                  Browse All Developers
                </a>
              </div>
            </div>
          </div>
          <div className="" id="clientSlider1">
            <div className="row">
              <Slider {...companyList} className="commercial-set">
                {allCompanies?.map((item) => (
                  <div className="col-2 text-center">
                    <Link to={`/company/${item?.slug}`} className="client-card">
                      <img src={item?.img} alt="Image" />
                    </Link>
                    <h3 style={{ fontSize: "12px", padding: "10px 0" }}>
                      {item?.company_name}
                    </h3>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/**********about ponties************* */}
      <section className="service-area-2 space hello-roxy ">
        <div className="container  " style={{ padding: "40px 40px" }}>
          <div className="row justify-content-between align-items-center">
            <div className="">
              <div className="title-area text-center">
                <h2 className="sec-title" style={{ color: "#fff" }}>
                  How Pontis Stands Out
                </h2>
                <p
                  className="sec-text"
                  style={{ color: "#fff", fontSize: "28px" }}
                >
                  Our values and shared goals come together to provide honest
                  and exceptional service.
                </p>
              </div>
            </div>
          </div>
          <div
            className="swiper-slide text-center "
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="service-card style3 "
                  style={{ padding: "20px" }}
                >
                  <div className="service-card-icon ">
                    <img src="assets/img/icon/service-icon3-1.svg" alt="Icon" />
                  </div>
                  <h3 className="box-title" style={{ color: "#fff" }}>
                    <a style={{ color: "#fff" }} href="/">
                      CLIENT SUCCESS
                    </a>
                  </h3>
                  <p
                    className="box-text"
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    We are dedicated to delivering quality customer service to
                    all our clients, regardless of the transaction's size. Our
                    priority is to make you feel comfortable during the process
                    and delighted with the results at the end. .
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="service-card style3 "
                  style={{ padding: "20px" }}
                >
                  <div className="service-card-icon ">
                    <img src="assets/img/icon/service-icon3-2.svg" alt="icon" />
                  </div>
                  <h3 className="box-title" style={{ color: "#fff" }}>
                    <a style={{ color: "#fff" }} href="/">
                      CODE OF ETHICS
                    </a>
                  </h3>
                  <p
                    className="box-text"
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    As a boutique brokerage, we maintain strict standards in
                    selecting our REALTORS. Those who do not align with our
                    Company Values won't be part of our team; our Realtors,
                    educated and trusted professionals, prioritize their
                    clients' needs as their primary objective.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="service-card style3 "
                  style={{ padding: "20px" }}
                >
                  <div className="service-card-icon ">
                    <img src="assets/img/icon/service-icon2-3.svg" alt="icon" />
                  </div>
                  <h3 className="box-title" style={{ color: "#fff" }}>
                    <a style={{ color: "#fff" }} href="/">
                      DILIGENCE & EXPERTISE
                    </a>
                  </h3>
                  <p
                    className="box-text"
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    Our team takes the time to ensure accuracy and does the
                    proper due diligence to ensure accuracy. Relax and leave the
                    hard work to the experts, allowing you to focus on what
                    matters while we handle the details with precision.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="contact-form"></div>
      </section>

      <div className="space-bottom  overflow-hidden container">
        <div
          className="sec-bg-shape2-1 spin shape-mockup d-xl-block d-none text-white"
          data-bottom="9%"
          data-left="34%"
        >
          <img src="assets/img/shape/section_shape_2_1.jpg" alt="img" />
        </div>
        <div
          className="sec-bg-shape2-2 wave-anim shape-mockup d-xl-block  text-white"
          data-top="9%"
          data-left="12%"
          data-bg-src="assets/img/shape/section_shape_2_2.jpg"
        ></div>
        <div
          className="container th-container2"
          style={{ padding: "120px 0 0 0" }}
        >
          <div className="row gx-60 align-items-center">
            <div className="col-lg-5">
              <div className="appointment-thumb text-xl-end mb-lg-0 mb-50">
                <img src="assets/img/played(1).jpg" alt="img" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="title-area mb-35">
                <h3 className="sec-title text-dark text-center">
                  Ready to Find the Perfect Property?
                  <br /> Let's Talk!
                </h3>
              </div>
              <form action="#">
                <div className="row">
                  <div className="form-group style-border3 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Your Name*"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                    <i className="fal fa-user"></i>
                  </div>
                  <div className="form-group style-border3 col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Your Email*"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <i className="fal fa-envelope"></i>
                  </div>
                  <div className="form-group style-border3 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      placeholder="Your Contact*"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                    />
                    <i className="fal fa-phone"></i>
                  </div>

                  <div className="col-12 form-group style-border3">
                    <i className="far fa-comments"></i>
                    <textarea
                      id="description"
                      placeholder="Type Your Message"
                      className="form-control"
                      value={formData.description}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div className="col-12 form-btn mt-4">
                    <button
                      type="button"
                      className="th-btn text-white mt-15"
                      onClick={handleFormSubmit}
                    >
                      SEND
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="th-modal modal fade"
        id="portfolioModal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="container">
              <button
                type="button"
                className="icon-btn btn-close bg-title-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-regular fa-xmark"></i>
              </button>
              <div className="page-single bg-title-dark">
                <div className="page-img mb-30">
                  <img
                    className="w-100 rounded-20"
                    src="assets/img/project/project_pop1_1.png"
                    alt="portfolio Image"
                  />
                </div>
                <div className="page-content">
                  <h2 className="h3 page-title text-white fw-medium">
                    Where Visibility Meets Success
                  </h2>
                  <div className="row gy-30">
                    <div className="col-xl-7">
                      <p className="mb-20 text-light">
                        The timeline varies depending on the complexity of the
                        project. Simple projects may take a few weeks, while
                        more complex ones could extend to several months.
                        Timelines are influenced by factors like scope, feedback
                        iterations, and client responsiveness.
                      </p>

                      <p className="mb-xl-4 mb-0 text-light">
                        Project timelines vary based on complexity and scope.
                        Small projects may take a few weeks, while larger ones
                        could span several months. Timelines are established
                        during project kickoff. We use a range of
                        industry-standard tools such as Sketch.
                      </p>
                    </div>
                    <div className="col-xl-5">
                      <div className="checklist">
                        <ul>
                          <li className="text-light">
                            <strong>Service Category:</strong> Rubix Carabil
                            Tower
                          </li>
                          <li className="text-light">
                            <strong>Clients:</strong> David Malan
                          </li>
                          <li className="text-light">
                            <strong>Project Date:</strong> 13 June, 2020
                          </li>
                          <li className="text-light">
                            <strong>Avenue End Date:</strong> 22 July, 2023
                          </li>
                          <li className="text-light">
                            <strong>Locations:</strong> NewYork - 2546 Firs, USA
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row gy-30 gx-40 align-items-center">
                    <div className="col-xl-6">
                      <div className="page-img mb-0">
                        <img
                          className="w-100 rounded-20"
                          src="assets/img/project/project_pop2_1.png"
                          alt="portfolio Image"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <h4 className="box-title text-white fw-medium">
                        Services Benefits:
                      </h4>
                      <p className="text-light">
                        We can do both. We can adhere to existing brand
                        guidelines, ensuring consistency, or help develop new
                        ones if a client is looking for a fresh identity. Our
                        goal is to align the UI/UX design with the brand's
                        overall strategy product meets the needs.
                      </p>
                      <div className="checklist style3">
                        <ul>
                          <li className="text-light">
                            <i className="far fa-check-circle"></i>We use the
                            latest diagnostic equipment
                          </li>
                          <li className="text-light">
                            <i className="far fa-check-circle"></i>Automotive
                            service our clients receive
                          </li>
                          <li className="text-light">
                            <i className="far fa-check-circle"></i>We are a
                            member of Professional Service
                          </li>
                          <li className="text-light">
                            <i className="far fa-check-circle"></i>Digital how
                            will activities impact traditional
                          </li>
                          <li className="text-light">
                            <i className="far fa-check-circle"></i>Architect and
                            technical engineer
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <div
          className="modal-content"
          style={{
            background: "#f4f4f4",
            borderRadius: "15px",
            padding: "20px",
            maxWidth: "400px",
            margin: "auto",
            border: "none",
          }}
        >
          <i
            className="bi bi-x-lg rgfront"
            style={{}}
            onClick={handleCloseModal}
          ></i>
          <div className="text-center mb-2">
            <img src={logo} alt="logo" style={{width:"200px"}} />
          </div>
          <h3
            style={{
              textAlign: "center",
              color: "#1C2D37",
              marginBottom: "20px",
              fontSize: "14px",
              textTransform: "uppercase",
            }}
          >
            I am interested
          </h3>
          <form className="widget-property-contact-form" id="customForm">
            <div className="form-group">
              <input
                style={{
                  border: "1px solid #ccc",
                  color: "#333",
                  background: "#fff",
                  borderRadius: "8px",
                  padding: "12px",
                  width: "100%",
                  marginBottom: "15px",
                }}
                type="text"
                className="form-control placeholder-style"
                placeholder="Name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <input
                style={{
                  border: "1px solid #ccc",
                  color: "#333",
                  background: "#fff",
                  borderRadius: "8px",
                  padding: "12px",
                  width: "100%",
                  marginBottom: "15px",
                }}
                type="email"
                className="form-control placeholder-style"
                placeholder="Email Address"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <input
                style={{
                  border: "1px solid #ccc",
                  color: "#333",
                  background: "#fff",
                  borderRadius: "8px",
                  padding: "12px",
                  width: "100%",
                  marginBottom: "15px",
                }}
                type="tel"
                className="form-control placeholder-style"
                placeholder="Phone Number"
                id="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <textarea
                name="description"
                id="description"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "12px",
                  width: "100%",
                  height: "120px",
                  marginBottom: "15px",
                }}
                placeholder="Type Your Message"
                className="form-control placeholder-style"
                value={formData.description}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <button
              type="button"
              className="th-btn"
              style={{
                backgroundColor: "#4b0082",
                color: "#fff",
                padding: "12px 20px",
                width: "100%",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={handleFormSubmit}
            >
              SEND
            </button>
          </form>
        </div>
      </Modal> */}
    </main>
  );
};

export default HomePage;
