import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "../index.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import stickyLogo from "../images/ponties.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CurrencyFormat from "react-currency-format";
import ReactWhatsapp from 'react-whatsapp';
import {Helmet} from "react-helmet";

const API_URL = process.env.REACT_APP_API_URL;

const PropertyDetail = () => {
  const [PropertyDetail, setPropertyDetail] = useState<any>();
  const [relatingProperty, setRelatingProperty] = useState<any[]>([]);
  const [companyDetail, setCompanyDetail] = useState<any>();
  const [floorPlan, A] = useState<any>([]);

  console.log(PropertyDetail, "this is PropertyDetail");
  const propertyslug = useParams();
  console.log(propertyslug, "this is my property Slug");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  /* ****************api for property list ************* */

  const getPropertyDetail = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/properties/${propertyslug?.slug}/patch/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setPropertyDetail(responseData);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };
  useEffect(() => {
    if (propertyslug?.slug) {
      getPropertyDetail();
    }
  }, [propertyslug?.slug]);

  const getAllProperties = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/properties/advance/filter/pagination/?company=${PropertyDetail?.company}`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setRelatingProperty(responseData?.results.filter(item => item.id !== PropertyDetail?.id));
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };
  useEffect(() => {
    if (PropertyDetail?.company) {
      getAllProperties();
    }
  }, [PropertyDetail?.state, PropertyDetail?.property_label_name,PropertyDetail?.company]);

  const getCompany = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/company/${PropertyDetail?.company}/patch/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        setCompanyDetail(responseData);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };
  const getFloorPlan = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/properties/bhk/${PropertyDetail?.id}/get/`
      );
      if ((response.status = 200)) {
        const responseData = response.data;
        // A(responseData);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };
  useEffect(() => {
    if (PropertyDetail?.company) {
      getCompany();
    }
  }, [PropertyDetail?.company]);
  useEffect(() => {
    if (PropertyDetail) {
      getFloorPlan();
    }
  }, [PropertyDetail?.id]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // const handleFormSubmit = () => {
  //   const { name, email, phone, description } = formData;

  //   // Validate form fields
  //   if (!name || !email) {
  //     toast.error('Name and Email are required.');
  //     return;
  //   }

  //   // Create a form data object
  //   const zohoFormData = new FormData();
  //   zohoFormData.append('xnQsjsdp', '364cddf1ed7837732ad6a5202b0cd61a1770e9a9e1c3324fa33a8c520390b1b5');
  //   zohoFormData.append('xmIwtLD', 'cf6d2e0808712f6a57ba3bb6d6986242a7ad414eb355fa8d17aa736020674df612ab78058affc1a24c36292486a6efa1');
  //   zohoFormData.append('actionType', 'TGVhZHM=');
  //   zohoFormData.append('returnURL', 'https://www.pontis.ae/properties');
  //   zohoFormData.append('Last Name', name); // Mapping Name input to Zoho's Last Name field
  //   zohoFormData.append('Email', email);
  //   zohoFormData.append('Phone', phone);
  //   zohoFormData.append('Description', description);
  //   zohoFormData.append('LEADCF1', PropertyDetail?.company_name);
  //   zohoFormData.append('LEADCF2', PropertyDetail?.property_name);

  //   // Send form data to Zoho CRM
  //   fetch('https://crm.zoho.com/crm/WebToLeadForm', {
  //     method: 'POST',
  //     body: zohoFormData,
  //   })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         // alert('Form submitted successfully!');
  //         toast.success("We Will Contact You Soon");

  //         setFormData({
  //           name: '',
  //           email: '',
  //           phone: '',
  //           description: '',
  //         });
  //       } else {
  //         toast.error('Failed to submit form.');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error submitting form:', error);
  //       toast.error('An error occurred while submitting the form.');
  //     });
  // };


  const removeHtmlTags = (str) => {
    str = sanitizeHtml(str);
    return str.replace(/<[^>]*>/g, ''); // Remove all HTML tags
  };
  
  const sanitizeHtml = (html: string | undefined | null) => {
    if (!html) return ""; // Return an empty string if html is undefined or null
    return html
      .replace(/<ul>|<\/ul>/g, "") // Remove the <ul> and </ul> tags
      .replace(
        /<li>/g,
        '<div class="detail-list-item"><i class="bi bi-circle-fill" style="font-size: 12px; margin-right: 5px;"></i> ' // Inline styling for the icon
      )
      .replace(/<\/li>/g, "</div>"); // Close the div for each list item
  };




  const handleFormSubmit = async () => {
    const { name, email, phone, description } = formData;

    // Validate form fields
    if (!name || !email) {
      toast.error("Name and Email are required.");
      return;
    }

    try {
      // Create a form data object
      const zohoFormData = new FormData();
      zohoFormData.append("name", name);
      zohoFormData.append("email", email);
      zohoFormData.append("mobile", phone);
      zohoFormData.append("message", description);
      zohoFormData.append("company", PropertyDetail?.company);
      zohoFormData.append("prop_id", PropertyDetail?.prop_id);
      zohoFormData.append("properties", PropertyDetail?.id);

      // Send form data to Zoho CRM with axios
      const response = await axios.post(
        `${API_URL}/properties/user/contact/post/`,
        zohoFormData
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("We Will Contact You Soon");

        setFormData({
          name: "",
          email: "",
          phone: "",
          description: "",
        });
      } else {
        toast.error("Failed to submit form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };
  return (
    <div>
    <Helmet>
      <title>{PropertyDetail?.property_name + " | Pontis" || 'Pontis'}</title>

      <meta name="description" content={removeHtmlTags(PropertyDetail?.description) || PropertyDetail?.property_name} />

      <link rel="canonical" href={window.location.href} />

      <meta property="og:title" content={PropertyDetail?.property_name + " | Pontis" || 'Pontis'} />
      <meta property="og:description" content={removeHtmlTags(PropertyDetail?.description) || 'Default description for sharing'} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      
      <meta name="twitter:title" content={PropertyDetail?.property_name + " | Pontis" || 'Pontis'} />
      <meta name="twitter:description" content={removeHtmlTags(PropertyDetail?.description) + " | Pontis" || 'Pontis'} />

      <meta name="robots" content="index, follow" /> 
      <meta name="author" content="Pontis Real Estate LLC" />
    </Helmet>
      <div
        className="breadcumb-wrapper"
        style={{
          backgroundImage: "url('../../assets/img/bg/breadcumb-bg.jpg')",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">
                  {PropertyDetail?.property_name}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="row mb-5 ">
            <div className="col-lg-6 mb-2">
              <div
                className="property-custom-card"
                style={{ position: "relative" }}
              >
                <div className="property-card-thumb img-shine">
                  <img
                    className="img-shine"
                    onClick={handleShow}
                    style={{ transform: "scale(1.05)", height: "358px" }}
                    src={PropertyDetail?.upload_featured_image}
                    alt="PropertyImage"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="row mb-3">
                {PropertyDetail?.get_property_image
                  .slice(0, 4)
                  ?.map((item, index) => (
                    <div key={index} className="col-lg-6 mb-3">
                      <div
                        className="property-custom-card"
                        style={{ position: "relative" }}
                      >
                        <div className="property-card-thumb img-shine">
                          <img
                            onClick={handleShow}
                            src={item}
                            alt="img"
                            style={{
                              height: "170px",
                              transform: "scale(1.05)",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* <div className="slider-area property-slider1">
            <div
              className="swiper th-slider mb-4"
              id="propertySlider"
             >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="property-slider-img">

                  </div>
                </div>

              </div>
            </div>

          </div> */}
          <div className="row gx-30">
            <div className="col-xxl-8 col-lg-7">
              <div className="property-page-single">
                <div className="page-content">
                  <div className="property-meta mb-30">
                    <a className="property-tag" href="">
                      Featured
                    </a>
                   {PropertyDetail?.is_property && <a className="property-tag-rg" href="">
                      Off Plan
                    </a>}
                    {/* <a href="blog.html"><img src="assets/img/icon/calendar.svg" alt="img" />05 Jun, 2024</a>
                                        <a href="blog.html"><img src="assets/img/icon/comments.svg" alt="img" />32 Comments</a> */}
                  </div>
                  <h2 className="page-title">About This Property</h2>

                  <p className="mb-30" dangerouslySetInnerHTML={{ __html: sanitizeHtml(PropertyDetail?.description) }}></p>


                  <h2 className="page-title mb-20">Property Overview</h2>
                  <ul className="property-grid-list">
                    <li>
                      <div className="property-grid-list-icon text-dark">
                        <img
                          src="../assets/img/icon/property-single-icon1-1.svg"
                          alt="img"
                        />
                      </div>
                      <div className="property-grid-list-details">
                        <h4 className="property-grid-list-title text-dark">
                          ID NO.
                        </h4>
                        <p className="property-grid-list-text text-dark">
                          {PropertyDetail?.prop_id}
                        </p>
                      </div>
                    </li>
                    {PropertyDetail?.building_type_name ? (
                      <li>
                        <div className="property-grid-list-icon">
                          <img
                            src="../assets/img/icon/property-single-icon1-2.svg"
                            alt="img"
                          />
                        </div>
                        <div className="property-grid-list-details">
                          <h4 className="property-grid-list-title text-dark">
                            Type
                          </h4>
                          <p className="property-grid-list-text text-dark">
                            {PropertyDetail?.building_type_name}
                          </p>
                        </div>
                      </li>
                    ) : null}

                    {PropertyDetail?.bedroom ? (
                      <li>
                        <div className="property-grid-list-icon">
                          <img
                            src="../assets/img/icon/property-single-icon1-4.svg"
                            alt="img"
                          />
                        </div>
                        <div className="property-grid-list-details">
                          <h4 className="property-grid-list-title text-dark">
                            Bedroom
                          </h4>
                          <p className="property-grid-list-text text-dark">
                            {PropertyDetail?.bedroom}{" "}
                          </p>
                        </div>
                      </li>
                    ) : null}
                    {PropertyDetail?.bathroom ? (
                      <li>
                        <div className="property-grid-list-icon">
                          <img
                            src="../assets/img/icon/property-single-icon1-5.svg"
                            alt="img"
                          />
                        </div>
                        <div className="property-grid-list-details">
                          <h4 className="property-grid-list-title text-dark">
                            Bathroom
                          </h4>
                          <p className=" text-dark">
                            {PropertyDetail?.bathroom}
                          </p>
                        </div>
                      </li>
                    ) : null}
                    {PropertyDetail?.property_label_name ? (
                      <li>
                        <div className="property-grid-list-icon">
                          <img
                            src="../assets/img/icon/property-single-icon1-6.svg"
                            alt="img"
                          />
                        </div>
                        <div className="property-grid-list-details">
                          <h4 className="property-grid-list-title text-dark">
                            Purpose
                          </h4>
                          <p className="property-grid-list-text text-dark">
                            For {PropertyDetail?.property_label_name}
                          </p>
                        </div>
                      </li>
                    ) : null}
                    {PropertyDetail?.property_size ? (
                      <li>
                        <div className="property-grid-list-icon">
                          <img
                            src="../assets/img/icon/property-single-icon1-7.svg"
                            alt="img"
                          />
                        </div>
                        <div className="property-grid-list-details">
                          <h4 className="property-grid-list-title text-dark">
                            Sqft
                          </h4>
                          <p className="property-grid-list-text text-dark">
                            {PropertyDetail?.property_size}
                          </p>
                        </div>
                      </li>
                    ) : null}
                  </ul>

                  <h3 className="page-title mt-50 mb-25">
                    Features & amenities
                  </h3>
                  <div className="row gy-3">
                    {PropertyDetail?.get_property_amenities_name?.map(
                      (item) => (
                        <div className="col-xxl-3 col-sm-6" key={item?.id}>
                          <div className="checklist">
                            <ul>
                              <li>
                                <i className="far fa-square-check"></i>
                                {item}
                              </li>
                            </ul>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  {PropertyDetail?.upload_availability_plan ? (
                    <>
                      <h3 className="page-title mt-45 mb-30">
                        Floor Plan{" "}
                      </h3>
                      <div className="location-map">
                        <div className="contact-map">
                          <iframe
                            src={PropertyDetail?.upload_availability_plan}
                            style={{
                              width: "100%",
                              height: "500px",
                            }}
                            title="Project Brochure"
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {PropertyDetail?.upload_project_brochure ? (
                    <>
                      <h3 className="page-title mt-45 mb-30">
                        Project Brochure{" "}
                      </h3>
                      <div className="location-map">
                        <div className="contact-map">
                          <iframe
                            src={PropertyDetail?.upload_project_brochure}
                            style={{
                              width: "100%",
                              height: "500px",
                            }}
                            title="Project Brochure"
                          />
                        </div>
                      </div>
                    </>
                  ) : null}

                  {floorPlan?.length > 0 ? (
                    <>
                      <h3 className="page-title mt-45 mb-30">Floor Plan</h3>
                      <Tabs
                        defaultActiveKey={floorPlan[0]?.name}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                      >
                        {floorPlan?.map((item, index) => (
                          <Tab eventKey={item?.name} title={item?.name}>
                            <iframe
                              src={item?.bhk_pdf}
                              style={{
                                width: "100%",
                                height: "500px",
                              }}
                              title="Floor Plan"
                            ></iframe>
                          </Tab>
                        ))}
                      </Tabs>
                    </>
                  ) : null}
                  {PropertyDetail?.upload_video ? (
                    <>
                      <h3 className="page-title mt-45 mb-30">
                        Property Video{" "}
                      </h3>
                      <div className="location-map">
                        <div className="contact-map">
                          <iframe
                            src={PropertyDetail?.upload_video}
                            style={{
                              width: "100%",
                              height: "500px",
                            }}
                            title="Project Brochure"
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  <h3 className="page-title mt-45 mb-30">Location</h3>
                  <div className="location-map">
                    <div className="contact-map">
                      <iframe
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo&q=${PropertyDetail?.latitude},${PropertyDetail?.longitude}`}
                        loading="lazy"
                      // style="border:0; width: 100%; height: 450px;"
                      ></iframe>
                    </div>
                    <div className="location-map-address">
                      <div className="thumb">
                        <img
                          src={PropertyDetail?.upload_featured_image}
                          alt="img"
                        />
                      </div>
                      <div className="media-body">
                        <h4 className="title">Address:</h4>
                        <p className="text">{PropertyDetail?.street_address}</p>
                        {/* <h4 className="title">Post Code:</h4>
                                                <p className="text">12345</p> */}
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-5">
              <aside className="sidebar-area ">
                <div
                  className="widget widget-property-contact"
                  style={{ background: "#EBEBEB", borderRadius: "10px" }}
                >
                  <h4
                    className="widget_subtitle "
                    style={{ background: "#FD2500" }}
                  >
                    For {PropertyDetail?.property_label_name}
                  </h4>
                  <h4 className="widget_price" style={{ color: "#1C2D37" }}>
                    AED{" "}
                    <CurrencyFormat
                      value={PropertyDetail?.property_price}
                      displayType="text"
                      thousandSeparator={true}
                      suffix=".00"
                    />
                    {/* {PropertyDetail?.lease_price_per_mo > 0
                      ? PropertyDetail?.lease_price_per_mo
                      : PropertyDetail?.property_price} */}
                  </h4>
                  <p className="widget_text" style={{ color: "#1C2D37" }}>
                    I am interested in this property
                  </p>
                  <form
                    action="#"
                    className="widget-property-contact-form"
                    id="customForm"
                  >
                    <div className="form-group">
                      <input
                        style={{
                          border: "1px solid #1C2D37",
                          color: "#1C2D37",
                          background: "#fff",
                        }}
                        type="text"
                        className="form-control placeholder-style"
                        placeholder="Name"
                        id="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        style={{
                          border: "1px solid #1C2D37",
                          color: "#1C2D37",
                          background: "#fff",
                        }}
                        type="text"
                        className="form-control placeholder-style"
                        placeholder="Email Address"
                        id="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        style={{
                          border: "1px solid #1C2D37",
                          color: "#1C2D37",
                          background: "#fff",
                        }}
                        type="text"
                        className="form-control placeholder-style"
                        placeholder="Phone Number"
                        id="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group ">
                      <textarea
                        name="Description"
                        id="description"
                        style={{
                          border: "1px solid #1C2D37",
                          borderRadius: "25px",
                        }}
                        placeholder="Type Your Message"
                        className="form-control placeholder-style "
                        value={formData.description}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="th-btn text-white mt-15"
                      onClick={handleFormSubmit}
                    >
                      SEND
                    </button>
                    <ReactWhatsapp
                  className="mt-10"
                   
                      element="webview" // Required prop
                      number="+971545294369"
                      message={`Hi, I am interested in ${PropertyDetail?.property_name}`}>
                    <i className="bi bi-whatsapp kn-btn" ></i>
                    </ReactWhatsapp>
                    </div>
                    {/* <ReactWhatsapp
                      className=" text-white mt-18 btn-success mr-2"
                      style={{borderRadius:"30px",padding:"13px 50px"}}
                      number="7018657677"
                      message={`Hi, I am Interested in ${PropertyDetail?.property_name}`}
                    /> */}
                    {/* <i className="bi bi-whatsapp" ></i> */}
                    {/* </ReactWhatsapp> */}
                  </form>
                </div>
                <div
                  className="widget  "
                  style={{ background: "#EBEBEB", borderRadius: "10px" }}
                >
                  <h3 className="widget_title" style={{ color: "#1C2D37" }}>
                    Featured Listing
                  </h3>
                  <div className="recent-post-wrap">
                    {relatingProperty?.map((item) => (
                      <div className="recent-post" key={item?.id}>
                        <div className="media-img">
                          <a href={`/property/${item?.slug}`}>
                            <img
                              style={{ height: "80px" }}
                              src={item?.upload_featured_image}
                              alt="Blog Image"
                            />
                          </a>
                        </div>
                        <div className="media-body">
                          <h4
                            className=""
                            style={{ color: "#1C2D37", fontSize: "18px" }}
                          >
                            <a className="" href={`/property/${item?.slug}`}>
                              {item?.property_name}
                            </a>
                          </h4>
                          <div className="recent-post-meta">
                            {item?.building_type_name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {companyDetail ? (
                  <>
                    <div
                      className="widget"
                      style={{ background: "#EBEBEB", borderRadius: "10px" }}
                    >
                      <div className="widget-banner text-center">
                        <div>
                          <Link
                            to={`/company/${companyDetail?.slug}`}

                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                width: "150px",
                                height: "150px",
                                border: "2px solid #fff",
                              }}
                              src={companyDetail?.img}
                              alt="Companyimg"
                            />
                          </Link>
                        </div>
                        {/* <h4 className="subtitle" style={{ fontSize: "18px" }}>
                          You Get Online support
                        </h4> */}
                        {/* <h5 className="link">
                        <a href={`tel:${companyDetail?.office_number}`}>
                          {companyDetail?.office_number}
                        </a>
                      </h5> */}

                        {/* <Link
                          to={`/company/${companyDetail?.slug}`}
                          className="th-btn mt-3 "
                        >
                          Read More
                        </Link> */}
                      </div>
                    </div>
                  </>
                ) : null}
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="container">
            <a
              className="close-button"
              onClick={handleClose}
              aria-label="Close"
              style={{
                fontSize: "20px",
                color: "#fff",
                cursor: "pointer",
                float: "right",
              }}
            >
              &times;
            </a>
            <Carousel
              swipeable={true}
              autoPlay={true}
              useKeyboardArrows={true}
              dynamicHeight={false}
              emulateTouch={true}
            >
              {PropertyDetail?.get_property_image?.map((item, index) => (
                <div key={index}>
                  <img src={item} alt="img" />
                </div>
              ))}
            </Carousel>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PropertyDetail;
